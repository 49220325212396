"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingCommons = require("@sm360/desking-commons");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../actions");
var _selectors = require("../selectors");
var _assignLead = _interopRequireDefault(require("./assignLead"));
var _getOrgRights = _interopRequireDefault(require("./api/getOrgRights"));
var _getUserInfos = _interopRequireDefault(require("./api/getUserInfos"));
var _loadQuote = _interopRequireDefault(require("./api/loadQuote"));
var _getCustomer = _interopRequireDefault(require("./api/getCustomer"));
var _loadMatches = _interopRequireDefault(require("./loadMatches"));
var _reloadTabItems = _interopRequireDefault(require("./items/reloadTabItems"));
var _reloadTabProtection = _interopRequireDefault(require("./protection/reloadTabProtection"));
var _reloadTradeIn = _interopRequireDefault(require("./tradeIn/reloadTradeIn"));
var _reloadContactSummary = _interopRequireDefault(require("./contact/reloadContactSummary"));
var _reloadIncentives = _interopRequireDefault(require("./incentives/reloadIncentives"));
var _getCreditApplicationDecision = _interopRequireDefault(require("./api/getCreditApplicationDecision"));
var _getContractValidationDecision = _interopRequireDefault(require("./api/getContractValidationDecision"));
var _getDealerInfo = _interopRequireDefault(require("./api/getDealerInfo"));
var _getLeadInfo = _interopRequireDefault(require("./api/getLeadInfo"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/* eslint-disable no-debugger */

/**
 * It will trigger the loadQuote saga
 *
 * @param {Object} payload - Data from action
 */
function initializeStore(_ref) {
  let {
    queryParams
  } = _ref;
  return function* () {
    yield (0, _effects.call)(_getUserInfos.default);
    const hasAccessToDesking = yield (0, _effects.select)(_selectors.getHasDeskingAccess);
    if (!hasAccessToDesking) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(new Error(), 'forbidden'));
      return;
    }
    const {
      quoteId,
      upg
    } = queryParams;
    const quote = yield (0, _effects.call)(_loadQuote.default, quoteId);
    if (!quote) {
      return;
    }
    const {
      hasTradeIns
    } = quote;
    if (hasTradeIns) {
      yield (0, _effects.fork)(_reloadTradeIn.default);
    }
    yield (0, _effects.call)(_getLeadInfo.default);
    yield (0, _effects.call)(_getDealerInfo.default);
    yield (0, _effects.put)(_deskingCommons.priceIncreaseActions.setPriceIncreaseRollCount(upg));
    yield (0, _effects.fork)(_loadMatches.default);
    yield (0, _effects.fork)(_reloadTabItems.default);
    yield (0, _effects.fork)(_reloadTabProtection.default);
    yield (0, _effects.call)(_reloadContactSummary.default);
    yield (0, _effects.fork)(_reloadIncentives.default);
    const orgRights = yield (0, _effects.call)(_getOrgRights.default);
    yield (0, _effects.put)(_actions.Creators.setOrgRights({
      orgRights
    }));
    const selectedOrgRights = yield (0, _effects.select)(_selectors.getOrgRights);
    const salesPersonnel = yield (0, _effects.select)(_selectors.getSalesPersonnel);
    const {
      businessManager
    } = salesPersonnel;
    const hasBusinessManager = businessManager && Object.entries(salesPersonnel.businessManager).length > 0;
    const isVehicleSold = quote.status === _commonsApi.StatusService.QUOTE_STATUS.SOLD;
    if (selectedOrgRights.BusinessManagerAssignationLead && !hasBusinessManager && isVehicleSold) {
      yield (0, _effects.call)(_assignLead.default, {
        personnelType: _deskingCommons.SALES_PERSONNEL.BUSINESS_MANAGER
      });
    }
    yield (0, _effects.call)(_getCreditApplicationDecision.default);
    yield (0, _effects.call)(_getContractValidationDecision.default);
    yield (0, _effects.call)(_getCustomer.default);
    yield (0, _effects.put)(_actions.Creators.setLoadingSuccess());
  }();
}
var _default = exports.default = initializeStore;