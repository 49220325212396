"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _uiLibrary = require("@sm360/ui-library");
var _commonsUtils = require("@sm360/commons-utils");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
function* fetchCustomFields(quoteId, queryParams, form) {
  try {
    const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.getTransactionPdfCustomFields, quoteId, form.instanceId, queryParams);
    if (response.data?.errMsg) {
      return {
        error: response.data.errMsg,
        form
      };
    } else if (response.errMsg) {
      return {
        error: response.errMsg,
        form
      };
    }
    return {
      response: response,
      form
    };
  } catch (error) {
    return {
      error: error.message,
      form
    };
  }
}
function _default(_ref) {
  let {
    forms,
    fromPrint
  } = _ref;
  return function* () {
    yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdating());
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
    try {
      const tasks = forms.filter(form => !(0, _commonsUtils.isEmpty)(form.customFields)).map(form => (0, _effects.call)(fetchCustomFields, quoteId, queryParams, form));
      const results = yield (0, _effects.all)(tasks);
      const errorResult = results.find(result => result.error);
      if (errorResult) {
        _uiLibrary.Toast.toast.error(errorResult.error);
        yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
        return;
      }
      const updatedForms = forms.map(form => {
        const resObj = results.find(res => res.form.formId === form.formId && res.form.instanceId === form.instanceId);
        const response = resObj?.response;
        if (response) {
          return {
            ...form,
            customFields: form.customFields.map(customFieldGroup => {
              const matchingGroup = response.groups.find(group => group.formFieldsGroupCode === customFieldGroup.formFieldsGroupCode);
              if (matchingGroup) {
                return {
                  ...customFieldGroup,
                  fields: customFieldGroup.fields.map(customField => {
                    const matchingField = matchingGroup.fields.find(field => field.formFieldCode === customField.formFieldCode);
                    const updatedField = {
                      ...customField
                    };
                    if (response.formLanguageOverrideCode && customField.formFieldCode === 'FormLanguageCode') {
                      updatedField.value = response.formLanguageOverrideCode;
                    }
                    if (matchingField && matchingField.resetCustomValue) {
                      updatedField.value = matchingField.value;
                    }
                    return updatedField;
                  })
                };
              }
              return customFieldGroup;
            })
          };
        }
        return form;
      });
      yield (0, _effects.put)(_actions.Creators.updateDealJacketForms(updatedForms, fromPrint));
    } catch (error) {
      _uiLibrary.Toast.toast.error(`${error}`);
    } finally {
      yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
    }
  }();
}