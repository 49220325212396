"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _uiLibrary = require("@sm360/ui-library");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
/**
 */
function _default(_ref) {
  let {
    creditPortalId,
    creditPortalFormIds
  } = _ref;
  return function* () {
    const t = yield (0, _effects.getContext)('t');
    yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdating());
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
    try {
      const customFields = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.getContractValidationCustomFields, quoteId, {
        ...queryParams,
        creditPortalId
      });
      const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.submitDealJacketForms, quoteId, {
        ...queryParams,
        formSubmissions: true
      }, {
        ...(customFields ?? {}),
        formSubmissions: creditPortalFormIds
      });
      if (response) {
        if (response.data?.errMsg) {
          _uiLibrary.Toast.toast.error(response.data.errMsg);
        } else if (response.errMsg) {
          _uiLibrary.Toast.toast.error(response.errMsg);
        } else {
          _uiLibrary.Toast.toast.success(t('deskingDesking.forms.toasts.successfulSubmission'));
        }
      }
      yield (0, _effects.delay)(2000);
      yield (0, _effects.put)(_actions.Creators.reloadTabForms());
    } catch (error) {
      _uiLibrary.Toast.toast.error(`${error}`);
      yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
    }
  }();
}