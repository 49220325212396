"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * @param {Object} state
 * @param {Object} payload - { forms, fromPrint }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    forms,
    fromPrint
  } = _ref;
  return {
    ...state,
    tabForms: {
      dealJacket: {
        ...(state?.tabForms?.dealJacket || {})
      },
      formsToEdit: {
        forms: forms || [],
        fromPrint: !!fromPrint
      },
      isPrinting: state?.tabForms?.isPrinting ?? false
    }
  };
};
exports.default = _default;