"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _selectors = require("../selectors");
var _vehicle = require("../selectors/vehicle");
function* _default() {
  const vehicleId = yield (0, _effects.select)(_vehicle.getVehicleId);
  const {
    lang
  } = yield (0, _effects.select)(_selectors.getConfig);
  const url = `${process.env.REACT_APP_XMS_APP_LEGACY_URL}/vehicleinventory/vignette?type=alternate&templang=${lang}&vehicleId=${vehicleId}`;
  window.open(url, '_blank');
}