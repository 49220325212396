"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _actions = require("../../actions");
/**
 */
function updateCustomer(_ref) {
  let {
    customer,
    isCoApplicantSelected = false,
    isCoApplicantEditing = false
  } = _ref;
  return function* () {
    const {
      organizationId,
      organizationUnitId,
      location,
      lang
    } = yield (0, _effects.select)(_selectors.getConfig);
    const leadId = yield (0, _effects.select)(_selectors.getLeadId);
    const queryParams = {
      orgId: organizationId,
      orgUnitId: organizationUnitId,
      location,
      lang
    };
    try {
      const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.updateCustomer, leadId, queryParams, customer);
      yield (0, _effects.put)(_actions.Creators.setCustomer({
        ...response,
        isCoApplicantSelected,
        isCoApplicantEditing
      }));
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'updateCustomer'));
    }
  }();
}
var _default = exports.default = updateCustomer;