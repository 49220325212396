"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PROFILE_FORM = void 0;
const translationPrefix = 'deskingDesking.contact';
const PROFILE_FORM = exports.PROFILE_FORM = [{
  legend: `${translationPrefix}.identity`,
  fields: [{
    input: 'identity',
    className: 'small-12 medium-4 large-3',
    skipLayout: true
  }]
}, {
  legend: `${translationPrefix}.driversLicence`,
  fields: [{
    input: 'drivingLicence',
    className: 'small-12 medium-4 large-3',
    skipLayout: true
  }]
}, {
  legend: `${translationPrefix}.contactInformation`,
  fields: [{
    input: 'phones',
    className: 'small-12'
  }, {
    input: 'emails',
    className: 'small-12'
  }]
}, {
  legend: `${translationPrefix}.location`,
  fields: [{
    input: 'location',
    className: 'small-12 medium-4 large-3',
    skipLayout: true
  }]
}];