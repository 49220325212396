"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * @param {Object} state
 * @param {Object} payload - { updatedForms, fromPrint }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    updatedForms,
    fromPrint
  } = _ref;
  return {
    ...state,
    tabForms: {
      dealJacket: {
        ...(state?.tabForms?.dealJacket || {}),
        forms: state?.tabForms?.dealJacket?.forms.map(form => {
          const matchingUpdatedForm = updatedForms.find(updatedForm => updatedForm.formId === form.formId && updatedForm.instanceId === form.instanceId);
          return matchingUpdatedForm ?? form;
        }) ?? []
      },
      formsToEdit: {
        forms: updatedForms,
        fromPrint
      },
      isPrinting: state?.tabForms?.isPrinting ?? false
    },
    isQuoteUpdating: false
  };
};
exports.default = _default;